<template>
  <!-- 编辑过户数量 -->
  <el-dialog class="productNamePopUpLayer" title="编辑过户数量" :visible.sync="transferQuantityVisible">
    <p class="available">
      可用数量: {{ availableNum }}{{ newListData && newListData[0] && newListData[0].unit }}
    </p>
    <p>合计过户数量:{{ totalNum }}{{ newListData && newListData[0] && newListData[0].unit }}</p>
    <Table :item-data="itemData" :list-data="newListData">
      <template #detailInvWeight="{ row }">
        <span>{{ row.detailInvWeight }}{{ row.unit }}</span>
      </template>
      <template #transferWeight="{ row }">
        <el-input-number
          v-model="row.transferWeight"
          :precision="3"
          :controls="false"
          size="small"
          placeholder="请输入"
          :min="0"
          :max="row.detailInvWeight - row.frozenWight"
          @change="sueForPeace"
        />
      </template>
    </Table>
    <div slot="footer" class="dialog-footer">
      <el-button
        class="widen-button"
        size="small"
        type="primary"
        @click="confirmData"
      >
        确定
      </el-button>
      <el-button
        class="widen-button"
        size="small"
        plain
        @click="close"
      >
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Table from '@/components/Table'
export default {
  components: { Table },
  props: {
    listData: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      transferQuantityVisible: false,
      newListData: [],
      itemData: [
        { label: '库区编号', prop: 'goodsYardNo', width: 180 },
        { label: '货位号', prop: 'detailLocationNo', width: 100 },
        { label: '货位描述', prop: 'detailLocationDesc', width: 100 },
        { label: '库存数量/单位', prop: 'detailInvWeight', width: 100 },
        { label: '过户数量', prop: 'transferWeight', width: 100 }
      ],
      totalNum: 0,
      availableNum: 0
    }
  },
  watch: {
    listData: {
      handler(data) {
        this.newListData = data.map((item) => {
          item.transferWeight = item.transferWeight || 0
          return item
        })
        this.availableNum = this.newListData && this.newListData.length > 0 && this.newListData[0].avlbWeight
        this.totalNum = this.newListData.reduce((prev, current) => prev + current.transferWeight, 0)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 关闭弹窗
    close() {
      this.transferQuantityVisible = false
    },
    // 填写过户数量后确定
    sueForPeace() {
      this.totalNum = this.newListData.reduce((prev, current) => prev + current.transferWeight, 0)
    },
    confirmData() {
      if (this.totalNum === 0 || !this.totalNum) return this.$message.warning('请至少录入一条不为0的过户数量')
      this.$emit('getTransferData', this.newListData)
      this.close()
    }
  }
}
</script>

<style scoped>
.available {
  position: absolute;
  top: 24px;
  left: 140px;
}
:deep .el-dialog__body {
  padding-top: 10px;
}
</style>
